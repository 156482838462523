import {
  SectionCol,
  SectionContainer,
  SectionInner,
  TextContainer,
} from "components/rhombus-UI/theme/containers";
import {
  FancyTitleMed,
  MainParagraph,
} from "components/rhombus-UI/theme/typography";
import React from "react";
import RhombusButton from "components/common/RhombusButton";

const MeetYourAccountManager = ({ videoUuid, managerName, meetingLink }) => {
  return (
    <SectionContainer>
      <SectionInner style={{ gap: "5rem" }}>
        <SectionCol style={{ display: "inline-block" }}>
          <div
            className="wistia_responsive_padding"
            style={{ padding: "56.25% 0 0 0", position: "relative" }}
          >
            <div
              className="wistia_responsive_wrapper"
              style={{
                height: "100%",
                left: "0",
                position: "absolute",
                top: "0",
                width: "100%",
              }}
            >
              <span
                className={`wistia_embed wistia_async_${videoUuid} popover=true popoverAnimateThumbnail=true videoFoam=true`}
                style={{
                  display: "inline-block",
                  height: "100%",
                  position: "relative",
                  width: "100%",
                }}
              ></span>
            </div>
          </div>
        </SectionCol>
        <SectionCol className="col">
          <TextContainer style={{ gap: "1.25rem" }}>
            <FancyTitleMed style={{ textAlign: "left" }}>
              Meet your dedicated Account Manager, {managerName}.
            </FancyTitleMed>
            <MainParagraph>
              Let's chat! Schedule a meeting with {managerName.split(" ")[0]}{" "}
              today to explore how Rhombus can enhance your property's security
              and operational efficiency.
            </MainParagraph>
          </TextContainer>
          <RhombusButton
            title="Book a Meeting"
            path={meetingLink}
            useHref
            target="_blank"
            type="gradient"
          />
        </SectionCol>
      </SectionInner>
    </SectionContainer>
  );
};

export default MeetYourAccountManager;
