import HeroLayout1 from "components/common/hero/HeroLayout1";
import Highlights from "components/common/Highlights";
import InfoSlider from "components/common/InfoSlider";
import MeetYourAccountManager from "components/landing-pages/templates/MeetYourAccountManager";
import GlobalLayout from "components/page/GlobalLayout";
import { graphql, StaticQuery } from "gatsby";
import React from "react";
import Helmet from "react-helmet";

export default function articleStudentLiving() {
  const renderContent = data => {
    const videoUuid = "l6ylvdkqek";
    return (
      <GlobalLayout color="var(--gradient-light)">
        <Helmet>
          <title>Rhombus + Article Student Living</title>
          <meta
            name="description"
            content="Elevate your property's security and join the eleven Article properties that have already upgraded to Rhombus."
          />
          <meta
            property="og:image"
            content="https://rhombus.com/img/MetaTag-Article-Student-Living.png"
          />
          <meta name="robots" content="noindex, nofollow" />
          <script
            src={`https://fast.wistia.com/embed/medias/${videoUuid}.jsonp`}
            async
          ></script>
          <script
            src="https://fast.wistia.com/assets/external/E-v1.js"
            async
          ></script>
        </Helmet>
        <HeroLayout1
          data={{
            preTitle: "Rhombus + article student living",
            title:
              "We want to help Article embrace a new era of physical security. ",
            description:
              "Elevate your property's security and join the eleven Article properties that have already upgraded to Rhombus. Your dedicated Account Manager, Wesley is ready to help.",
            image: data.headerImage,
            button1: {
              text: "Book a Meeting",
              path:
                "https://outlook.office.com/bookwithme/user/814bff4f5d0f4189806c332047b5ebb0@rhombus.com?anonymous&ep=pcard",
              useHref: true,
              target: "_blank",
            },
          }}
          useTitleCase={false}
        />
        <Highlights
          data={{
            title: "The Perks of Rhombus for Article Property Managers",
            image: data.highlightImage,
            list: [
              {
                title: "Spend less time searching through footage",
                description:
                  "Use advanced search features to quickly find footage and share video content securely via text, email, or URL.",
              },
              {
                title: "Receive instant smart notifications",
                description:
                  "Stay ahead of potential threats with proactive AI alerts, offering real-time detection and customizable notifications.",
              },
              {
                title: "Stay in the know from web or mobile",
                description:
                  "Get the complete picture of activity across all locations. Remotely view and manage cameras from one unified cloud platform",
              },
              {
                title: "Get rid of bulky hardware",
                description:
                  "No more complex NVR/DVRs. Rhombus’ modern architecture ensures fast and easy deployment with a single cable.",
              },
            ],
          }}
          center
          color="var(--white)"
        />

        <InfoSlider
          title="Feature-Rich Security Platform for Student Housing"
          data={[
            {
              tabTitle: "Smart Search",
              preTitle: "FASTER INVESTIGATIONS",
              title: "Enjoy an all-in-one solution that grows with your needs.",

              description:
                "Accelerate investigations by pinpointing footage based on key details. Search using AI analytics and find activity based on human movement, facial recognition, license plate recognition, audio, color search, and more.",
              image: data.benefit1,
            },
            {
              tabTitle: "Easy Sharing",
              preTitle: "COMMUNICATE & COLLABORATE",

              title: "Easy Sharing",
              description:
                "Collaboration is easy, fast, and flexible. Securely share video clips, livestreams, and historical streams with anyone—send footage via text, email, and URL and view on any device with no login required.",
              image: data.benefit2,
            },
            {
              tabTitle: "User Roles",
              preTitle: "MANAGE END-USERS",

              title: "User Roles",
              description:
                "Seamlessly add unlimited locations, users, and devices with no tiers or seat limits. Gain granular control over user permissions with custom roles and access types that make it easy to scale geographically and vertically within your organization.",
              image: data.benefit3,
            },
            {
              tabTitle: "In-Depth Reporting",
              preTitle: "DATA-DRIVEN INSIGHTS",

              title: "In-Depth Reporting",
              description:
                "Get comprehensive visibility into system performance and operations with diagnostic logs, audit logs, bandwidth reports, device inventory, device up/down time, and more.",
              image: data.benefit4,
            },
            {
              tabTitle: "Open & Integrated",
              preTitle: "YOUR SECURITY, YOUR WAY",
              title: "Open & Integrated",
              description:
                "Leverage your existing technologies with plug-and-play integrations for your favorite third-party solutions. Plus, enjoy the freedom to customize your system to your organization's unique needs—use Rhombus' open API to build custom functionality.",
              image: data.benefit5,
            },
          ]}
          color="var(--nuetral-100)"
        />
        <MeetYourAccountManager
          managerName="Wesley Hernandez"
          meetingLink="https://outlook.office.com/bookwithme/user/814bff4f5d0f4189806c332047b5ebb0@rhombus.com?anonymous&ep=pcard"
          videoUuid={videoUuid}
        />
      </GlobalLayout>
    );
  };

  const query = graphql`
    query {
      headerImage: file(
        relativePath: {
          eq: "components/landing-pages/img/case-study-highlight/article-student-living-hero.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      highlightImage: file(
        relativePath: {
          eq: "components/landing-pages/img/case-study-highlight/console.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            quality: 100
            placeholder: BLURRED
          )
        }
      }
      benefit1: file(
        relativePath: {
          eq: "components/landing-pages/img/case-study-highlight/smart-search.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit2: file(
        relativePath: {
          eq: "components/landing-pages/img/case-study-highlight/shared-clip.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit3: file(
        relativePath: {
          eq: "components/landing-pages/img/case-study-highlight/user-roles.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit4: file(
        relativePath: {
          eq: "components/landing-pages/img/case-study-highlight/in-depth-reporting.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
      benefit5: file(
        relativePath: {
          eq: "components/landing-pages/img/case-study-highlight/integrations.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, quality: 100)
        }
      }
    }
  `;

  return <StaticQuery query={query} render={renderContent} />;
}
